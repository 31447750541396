import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Wizard from "./components/Wizard";
import Question from "./components/Question";
import Measure from "./components/Measure";

import bmwi from "./assets/html_logo_bmwi.svg";
import mittelstand_digital from "./assets/html_logo_mittelstand_digital.png";
import mittelstand_lingen from "./assets/html_logo_mittelstand4.svg";
import { getApiUrl } from "./utils";

const App = () => {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/measure/:id">
                        <Measure />
                    </Route>
                    <Route path="/">
                        <Form />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
};

const Form = () => {
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [suggestedMeasures, setSuggestedMeasures] = useState([]);
    const [pastEvaluations, setPastEvaluations] = useState([]);

    const handleInput = e => {
        e.preventDefault();
        const { name, value } = e.target;
        setAnswers({ ...answers, [name]: value });
    };
    useEffect(() => {
        const cache = JSON.parse(localStorage.getItem("evaluations"));
        let pastEval = cache === null ? [] : cache.reverse();
        setPastEvaluations(pastEval);
        fetch(`${getApiUrl()}/questions`)
            .then(res => res.json())
            .then(data => setQuestions(data));
    }, []);

    const evaluateAnswers = () => {
        const evalForm = Object.entries(answers).map(([k, v]) => {
            return { questionUUID: k, value: v };
        });
        fetch(`${getApiUrl()}/evaluate`, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify(evalForm),
        })
            .then(res => res.json())
            .then(data => {
                setSuggestedMeasures(data);
                let pastEval =
                    JSON.parse(localStorage.getItem("evaluations")) || [];
                pastEval.push({
                    date: new Date().toLocaleString(),
                    answers: JSON.stringify(answers),
                    measures: JSON.stringify(data),
                });
                localStorage.setItem("evaluations", JSON.stringify(pastEval));
            })
            .then(() => setIsSubmitted(true));
    };

    const loadEvaluationFrom = date => {
        let pastEval = JSON.parse(localStorage.getItem("evaluations")) || [];
        pastEval.forEach(evaluation => {
            if (evaluation.date === date) {
                setAnswers(JSON.parse(evaluation.answers));
            }
        });
    };
    return (
        <main className="App">
            {!isSubmitted && (
                <div className="container shadowbox ">
                    <Wizard groupSize={1}>
                        {questions.map(question => (
                            <Question
                                key={question.uuid}
                                question={question}
                                answers={answers}
                                handleInput={handleInput}
                            />
                        ))}
                    </Wizard>
                    <div className="submit" onClick={evaluateAnswers}>
                        Auswertung starten
                    </div>
                    {pastEvaluations.length > 0 && (
                        <div id="pastEvaluations">
                            <h4>
                                Bereits durchgeführte Auswertungen erneut laden
                            </h4>
                            <ul>
                                {pastEvaluations.map(evaluation => {
                                    return (
                                        <li
                                            onClick={() =>
                                                loadEvaluationFrom(
                                                    evaluation.date
                                                )
                                            }
                                        >
                                            Auswertung vom {evaluation.date}{" "}
                                            laden
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            )}
            {isSubmitted && (
                <div className="container shadowbox ">
                    <p>
                        {suggestedMeasures.length === 0
                            ? "Leider konnten wir keine Maßnahmen anhand Ihrer Eingaben ermitteln."
                            : "Anhand Ihrer Angaben empfehlen wir die folgenden Maßnahmen."}
                    </p>
                    <ul>
                        {suggestedMeasures.map((measure, index) => {
                            return (
                                <li>
                                    <h3>{`${index + 1}. ${measure.name}`}</h3>
                                    <p>{measure.excerpt}</p>
                                    <Link
                                        className="submit"
                                        to={`/measure/${measure.uuid}`}
                                        target="_blank"
                                    >
                                        Mehr Informationen
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
            <div id="sponsors">
                <div>
                    <img src={bmwi} alt="BMWI" />
                </div>
                <div>
                    <img src={mittelstand_digital} alt="Mittelstand Digital" />
                </div>
                <div>
                    <img
                        src={mittelstand_lingen}
                        alt="Mittelstandszentrum Lingen"
                    />
                </div>
            </div>
            <div id="links">
                <a href="/sites/impressum.html">Impressum</a>
                <a href="/sites/privacy.html">Datenschutzerklärung</a>
            </div>
        </main>
    );
};

export default App;
