export const getApiUrl = () => {
    const previewIndicator = "pas.sei-farbenfroh.de";
    const prodIndicator = "smartfarmcheck.digital";
    const currentUrl = window.location.host;
    if (currentUrl.includes(previewIndicator)) {
        return "https://pas.sei-farbenfroh.de/v1";
    } else if (currentUrl.includes(prodIndicator)) {
        return "https://smartfarmcheck.digital/v1";
    } else {
        return "http://localhost:8081/v1";
    }
};
