import React, { useState } from "react";
import "./input.css";

const NumberInput = props => {
    const { value, onChange, label, name, className } = props;
    const [hasValue, setHasValue] = useState(
        value !== null && value !== undefined
    );

    return (
        <div className="inputField">
            <input
                type="number"
                name={name}
                value={value}
                onChange={e => {
                    setHasValue(true);
                    onChange(e);
                }}
                className={`${hasValue ? "hasValue " : ""} ${className}`}
            />
            <label>{label}</label>
        </div>
    );
};

export default NumberInput;
