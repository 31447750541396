import React, { useState } from "react";

import "./index.css";

const Wizard = ({ groupSize = 1, children }) => {
    const maxSteps =
        Math.floor(children.length / groupSize) + (groupSize === 1 ? 0 : 1);
    const [step, setStep] = useState(0);
    const nextStep = () => {
        if (step !== maxSteps - 1) {
            setStep(prev => prev + 1);
        }
    };
    const stepBack = () => {
        if (step !== 0) {
            setStep(prev => prev - 1);
        }
    };

    const renderGroup = () => {
        let jsx = [];
        for (let i = step * groupSize; i < step * groupSize + groupSize; i++) {
            jsx.push(children[i]);
        }
        return jsx;
    };
    return (
        <div className="wizard">
            <div id="step">{`Schritt ${step + 1}/${maxSteps}`}</div>
            {renderGroup()}
            <div className="button-container">
                {step !== 0 && (
                    <span className="submit prev" onClick={stepBack}>
                        Vorherige Frage
                    </span>
                )}
                {step !== maxSteps - 1 && (
                    <span onClick={nextStep} className="submit next">
                        Nächste Frage
                    </span>
                )}
            </div>
        </div>
    );
};

export default Wizard;
