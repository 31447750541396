import React from "react";
import { TextInput, SelectInput, NumberInput } from "../Input";

const Question = ({ question, answers, handleInput }) => {
    const { validator } = question;
    const getInputComponent = () => {
        const props = {
            label:
                question.text +
                (question.unit !== undefined
                    ? `Angabe in ${question.unit}`
                    : ""),
            value: answers[question.uuid],
            onChange: handleInput,
            name: question.uuid,
        };
        switch (validator.inputType) {
            case "SELECT": {
                return (
                    <SelectInput
                        {...props}
                        options={validator.options.map(option => {
                            return { id: option, name: option };
                        })}
                    />
                );
            }
            case "TEXT": {
                return <TextInput {...props} />;
            }
            case "NUMBER": {
                return <NumberInput {...props} />;
            }
        }
    };
    return (
        <div key={question.uuid} className="question">
            {getInputComponent()}
            {question.description && <p>&#128712; {question.description}</p>}
        </div>
    );
};

export default Question;
