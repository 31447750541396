import React, { useState } from "react";

const SelectInput = props => {
    const { value, onChange, label, name, className, options = [] } = props;
    const [hasValue, setHasValue] = useState(
        value !== null && value !== undefined
    );

    return (
        <div className="inputField">
            <select
                onChange={e => {
                    setHasValue(true);
                    onChange(e);
                }}
                value={value}
                name={name}
                className={`${hasValue ? "hasValue " : ""} ${className}`}
            >
                <option></option>
                {options.map(option => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
            <label>{label}</label>
        </div>
    );
};

export default SelectInput;
