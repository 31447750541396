import React, { useState, useEffect } from "react";
import {withRouter} from "react-router-dom";
import bmwi from "../../assets/html_logo_bmwi.svg";
import mittelstand_digital from "../../assets/html_logo_mittelstand_digital.png";
import mittelstand_lingen from "../../assets/html_logo_mittelstand4.svg";
import {getApiUrl} from "../../utils";

const Measure_ = ({match}) => {
    const { id } = match.params;

    const [measure, setMeasure] = useState({ resources: [] });
    useEffect(() => {
        fetch(`${getApiUrl()}/measures/${id}`)
            .then(res => res.json())
            .then(data => setMeasure(data));
    }, [id]);
    return (
        <main className="measure">
            <h2>{measure.name}</h2>
            <p dangerouslySetInnerHTML={{ __html: measure.description }}></p>
            {measure.resources.length > 0 && (
                <div>
                    <h3>Weitere Informationen</h3>
                    {measure.resources.map(resource => {
                        let media;
                        if (resource.mime.indexOf("image") >= 0) {
                            media = (
                                <img
                                    src={`${getApiUrl()}/measures/${id}/resource/${resource.name}`}
                                    alt={resource.name}
                                />
                            );
                        } else if (resource.mime.indexOf("video") >= 0) {
                            media = <video></video>;
                        }
                        return (
                            <div>
                                <p>{resource.description}</p>
                                {media}
                            </div>
                        );
                    })}
                </div>
            )}
            <div id="sponsors">
                <div>
                    <img src={bmwi} alt="BMWI" />
                </div>
                <div>
                    <img src={mittelstand_digital} alt="Mittelstand Digital" />
                </div>
                <div>
                    <img
                        src={mittelstand_lingen}
                        alt="Mittelstandszentrum Lingen"
                    />
                </div>
            </div>
            <div id="links">
                <a href="/sites/impressum.html">Impressum</a>
                <a href="/sites/privacy.html">Datenschutzerklärung</a>
            </div>
        </main>
    );
};

const Measure = withRouter(Measure_);

export default Measure;
